@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

@font-face {
  font-family: "Oxta";
  src: local("Oxta"), url("./asset/fonts/Oxta.woff") format("woff");
}

* {
  font-family: 'Roboto Mono', monospace;
  margin: 0;
  
}

body {
  background-color: #000000 !important;
  width: 100%;
  height: 100%;
  
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 100%;
}

::-webkit-scrollbar-thumb {
  background-color: #00ff78;
}

::-webkit-scrollbar-track {
  background-color: #ffffff09;
}

.side-bar {
  position: fixed;
  height: '100vh'; 
  overflow-y: auto
  /* overflowY: 'auto'; */
}


button.splide__arrow.splide__arrow--next {
  color: #00ff87 !important; 
  background-color: #00ff87 !important;
  right: -.5em !important;
  width: 40px;
  height: 40px;
  font-size: 1rem;
  opacity: 100%;
}

button.splide__arrow.splide__arrow--prev {
  color: #00ff87 !important; 
  background-color: #00ff87 !important;
  left: -.5em !important;
  width: 40px;
  height: 40px;
  font-size: 1rem;
  opacity: 100%;
}

.splide__pagination {
 bottom: -0.9em !important;
}

.splide__pagination__page {
  background: #2E2E2E !important;
  width: 10px !important;
  height: 3px !important;
  border-radius: 0% !important;
  
}

.splide__pagination__page.is-active {
  color: #00ff87 !important; 
  background-color: #00ff87 !important;
}

.glass-card {
  border: 1px solid rgba(255, 255,  255,  0.135);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.9px);
  -webkit-backdrop-filter: blur(10.9px);
}

.border-radius {
    border-radius: 10px !important;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha channel value here */
    z-index: 9999;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #00ff78;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
100% {
  transform: rotate(360deg);
}
}

/* spinner css */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #00ff78;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}



/* Loader 1 */

/* HTML: <div class="loader"></div> */




.loader {
  width: 60px;
  height: 16px;
  display: grid;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  --c:no-repeat linear-gradient(#00ff78 0 0);
  background: var(--c), var(--c), var(--c);
  animation: l16-1 1.5s infinite linear, l16-2 1.5s infinite linear;
  transform: scale(var(--s,1)) translate(3px,-3px);
}
.loader:after {
  --s:-1;
}
@keyframes l16-1 {
  0%,
  3%    {background-size: 0    4px,4px 0   ,0    4px}
  16.67%{background-size: 100% 4px,4px 0   ,0    4px}
  33.33%{background-size: 100% 4px,4px 100%,0    4px}
  46%,
  54%   {background-size: 100% 4px,4px 100%,100% 4px}
  66.67%{background-size: 0    4px,4px 100%,100% 4px}
  83.33%{background-size: 0    4px,4px 0   ,100% 4px}
  96%,
  100%  {background-size: 0    4px,4px 0   ,0    4px}
}
@keyframes l16-2 {
  0%,49.9%{background-position: 0    0,100% 0   ,100% 100%}
  50%,100%{background-position: 100% 0,100% 100%,0    100%}
}


/* loading data */
/* HTML: <div class="loader"></div> */
.load {
  width: 75px;
  aspect-ratio: 1; 
  display: grid;
}
.load:before,
.load:after {
  content: "";
  grid-area: 1/1;
  width: 35px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px #00ff78 inset;
  filter: drop-shadow(40px 40px 0 #00ff78);
  animation: l8 2s infinite alternate;
}
.load:after {
  margin: 0 0 0 auto; 
  filter: drop-shadow(-40px 40px 0 #00ff78);
  animation-delay: -1s;
}
@keyframes l8 {
  0%,10%   {border-radius:0}
  30%,40%  {border-radius:50% 0}
  60%,70%  {border-radius:50%}
  90%,100% {border-radius:0 50%}
}



.sticky {
  position: sticky;
  top: 0;
  z-index: 8;
}

.sticky + .content {
  padding-top: 102px;
}


.prediction-component {
  max-width: 100px; /* Adjust as needed */
  flex: 0 0 auto; /* Allow component to shrink and not grow beyond its content */
  margin-right: 10px; /* Adjust spacing between prediction components */
}

/* @media screen and (max-width: 768px) {
  .prediction-component {
    max-width: 100%; 
  }
} */



.custom-button {
  padding-left: 0.5rem;  
  padding-right: 0.5rem;  
  padding-top: 0.5rem;    
  padding-bottom: 0.5rem; 
  border-radius: 0.5rem;  
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  display: flex;         
  align-items: center;    
  justify-content: center;
  font-weight: 800;      
}

@media (min-width: 768px) {
  .custom-button {
      width: 200px;
  }
}




.prediction-badge {
  display: inline-block;
  background-color: #4CAF50; /* Green background */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.prediction-badge span {
  display: block;
}

@media (max-width: 768px) {
  .prediction-badge {
    width: 90%; /* Make the badge more fluid on smaller screens */
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .prediction-badge {
    font-size: 12px;
    padding: 8px 15px;
  }
}
